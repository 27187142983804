$navbar-base-color: #333;
$navbar-text-color: #FFF;
$navbar-highlight-color: rgb(230, 22, 22);

.word-track-content{
  ul{
    padding: 0;
    li{
      padding: 20px;
      display: block;
      min-height:100px;
      border-bottom: 1px solid black;
      &:hover {
        background-color: #ddd;
        border: 1px solid black;
      }
      &:after {
        content: "[Select]";
        float: right;
        padding-left: 10px;
        position: relative;
        top: -10px;
      }
      strong {
        font-weight: bold;
        font-style: italic;
      }
    }
  }
}

.h4 {
  display: inline;
  white-space: pre;
  margin-right: 1px
}

.-oneX-link--inline {
  display: inline;
  margin-right: 1px
}

textarea {
  width: 488px;
  height: 175px;
}

.custom-button {
  // padding: 20px;
  // background-color: blue;
  // color: black;
  ul{
    padding: 0;
    li{
      padding: 20px;
      display: block;
      min-height:100px;
      border-bottom: 1px solid black;
      &:hover {
        background-color: #ddd;
        border: 1px solid black;
      }
      strong {
        font-weight: bold;
        font-style: italic;
      }
    }
  }
}

.custom-template-content{
  ul{
    padding: 0;
    li{
      padding: 20px;
      display: block;
      min-height:100px;
      border-bottom: 1px solid black;
      &:hover {
        background-color: #ddd;
        border: 1px solid black;
      }
      strong {
        font-weight: bold;
        font-style: italic;
      }
    }
  }
}

a.-oneX-link--inline {
  color: black;
  font-size: small;
  text-decoration: none;
  float: right;
}
a.-oneX-link--inline:hover
{
  color: rgb(230, 22, 22);
  text-decoration: underline;         
}

.template-status {
  float: left;
  font-size: 14px;
  text-transform: capitalize;
  color: nth($accent-life-green, 1);
  transition: transform 3s;
  transform: translate3d(0, -100%, 0);   
  }

  .template-error-status {
    margin-top: 40px;
    color: rgb(230, 22, 22);
    float: left;
    font-size: 14px;
    text-transform: capitalize;
    transition: transform 3s;
    transform: translate3d(0, -100%, 0);   
    }